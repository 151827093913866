<template>
  <div class="author-detail ct-home page-author-detail" v-if="author">
    <!-- <div class="dc-header ct-home-content">
      <b-container fluid class="d-flex align-items-center container">
        <router-link :to="{ name: 'store' }">
          <div>ショップ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-2"></b-icon>
        <router-link :to="{ name: 'ListAuthorStore' }">
          <div>著者一覧</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-2"></b-icon>
        <div>著者詳細</div>
      </b-container>
    </div>
    <div class="author-detail mt-3">
      <div class="container">
        <div class="row">
          <h4 class="font-weight-bold col-sm-12 font-title mb-3">
            {{ author && author.name }}
          </h4>
          <div class="col-sm-5">
            <div
              class="card-img"
              :class="{
                'd-flex  align-items-center justify-content-center img-author':
                  author && !author.avatar,
              }"
            >
              <img
                v-if="author && author.avatar"
                :src="`${urlBackend}/${author.avatar}`"
                alt=""
                class="img-block-hover"
              />
              <div
                class="d-flex align-items-center justify-content-center img-author"
                v-else
              >
                <h5 class="font-weight-bold text-truncate-title text-center">
                  {{ author && author.name }}
                </h5>
              </div>
            </div>
          </div>
          <div class="col-sm-7">
            <h5 class="font-weight-bold font-title">著者プロフィール</h5>
            <div class="des-author">
              <p class="text-align-justify">
                {{ author && author.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="mask">
      <figure>
        <span class="imgWrap">
          <img
            width="150"
            height="150"
            v-if="author && author.avatar"
            :src="`${urlBackend}/${author.avatar}`"
            class="attachment-thumbnail size-thumbnail wp-post-image"
            alt=""
            loading="lazy"
            sizes="(max-width: 150px) 100vw, 150px"
          />
          <img v-else :src="`${no_image}`" />
        </span>
        <figcaption>
          <h2 class="name">
            <div>{{ author && author.name }}</div>
          </h2>
          <div class="position pcOnly">
            <div>{{ author && author.semi_title }}</div>
          </div>
        </figcaption>
      </figure>
    </div>
    <div class="inner lineHeight">
      <div class="aboutAuthor">
        <div class="topic">
          <span class="titleBorder"></span>
          <span class="title">著者について</span>
        </div>
        <div class="description des-author" id="authorDescriptionText">
          <p>{{ author && author.description }}</p>
        </div>
      </div>
    </div>
    <div
      class="inner lineHeight container author-content mt-3 lineHeight desktop px-lg-0"
    >
      <template>
        <div class="topic">
          <span class="titleBorder"></span>
          <span class="title">未購入のコンテンツ</span>
        </div>
        <div
          class="card-content pt-3 row"
          v-if="listContent.data && listContent.data.length"
        >
          <div
            class="link-content col-sm-2 col-md-4 col-lg-4 ct-home-card border-0"
            v-for="(value, index) in listContent.data"
            :key="index"
          >
            <b-card
              title="Card Title"
              tag="article"
              class="list-content"
              no-body
            >
              <div @click="returnDetailContent(value.id)">
                <div
                  class="img-card-custom card-img border-bottom"
                  :class="{
                    'd-flex  align-items-center justify-content-center img-content-author no-img':
                      !value.image_url,
                  }"
                >
                  <img
                    class="img-block-hover"
                    v-if="value.image_url"
                    :src="`${urlBackend}/storage/${value.image_url}`"
                  />
                  <div
                    class="d-flex align-items-center justify-content-center img-author"
                    v-else
                  >
                    <h5 class="text-truncate-title text-center">
                      {{ value.title }}
                    </h5>
                  </div>
                </div>
                <b-card-body>
                  <router-link
                    class="link-detail text-decoration-none"
                    :to="{
                      name: $route.params.shopId
                        ? 'content detail'
                        : 'content detail domain',
                      params: { id: value.id },
                    }"
                  >
                    <b-card-text
                      class="description-content text-truncate-title font-weight-bold mb-3"
                    >
                      {{ value.title }}
                    </b-card-text>
                  </router-link>
                  <b-card-text
                    class="description-content text-truncate-title font-weight-bold"
                  >
                    <img
                      width="150"
                      height="150"
                      v-if="value.avatar"
                      :src="`${urlBackend}/${value.avatar}`"
                      class="content-img-author"
                      alt=""
                      loading="lazy"
                    />
                    <img
                      v-else
                      :src="`${no_image}`"
                      class="content-img-author"
                    />
                    <span class="ml-2">{{ value.name }}</span>
                  </b-card-text>
                  <b-card-text class="description-content font-weight-bold">
                    {{
                      value.column &&
                      new Intl.NumberFormat("ja-JP", {
                        style: "currency",
                        currency: "JPY",
                        currencyDisplay: "symbol",
                      }).format(JSON.parse(value.column)[0].product_price)
                    }}
                  </b-card-text>
                </b-card-body>
              </div>
              <b-card-body style="padding: 1.25rem 1rem">
                <div class="d-flex align-items-center justify-content-between">
                  <button
                    v-if="!check(value.id)"
                    @click="addToCartBtn(value.id)"
                    variant="danger"
                    class="m-auto px-2 btn-success btn-width btn"
                    style="min-width: 75%"
                    :disabled="checkloading(value.id)"
                    :name="`add_content_${value.id}`"
                  >
                    <b-spinner v-if="checkloading(value.id)" small></b-spinner>
                    <span> カートに入れる</span>
                  </button>
                  <b-button
                    v-else
                    class="m-auto px-2"
                    style="cursor: default; min-width: 75%"
                    :disabled="true"
                  >
                    カートに追加済み
                  </b-button>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
        <div v-else class="card-content pt-3 row">
          <p class="col-md-12">著者が作成したコンテンツはありません</p>
        </div>
        <div class="d-flex justify-content-center position-relative m-auto">
          <CPagination
            v-if="listContent.data && listContent.last_page > 1"
            :activePage.sync="page"
            :pages="listContent.last_page"
            align="center"
          />
        </div>
      </template>
    </div>
    <div class="allList listBox mobile">
      <h3>未購入のコンテンツ</h3>

      <ul
        class="productsIndex allIndex"
        v-if="listContent.data && listContent.data.length"
      >
        <li
          v-for="(value, index) in listContent.data"
          :key="index"
          class="d-flex"
        >
          <a class="text-decoration-none">
            <figure>
              <div
                class="imgWrap d-flex align-items-center justify-content-center"
                @click="returnDetailContent(value.id)"
                :class="{
                  'no-img': !value.image_url,
                }"
              >
                <img
                  v-if="value && value.image_url"
                  :src="`${urlBackend}/storage/${value.image_url}`"
                />
                <span v-else class="text-truncate-title text-center">{{
                  value.title
                }}</span>
              </div>
              <figcaption>
                <div @click="returnDetailContent(value.id)">
                  <div class="ttl">
                    <div>{{ value.title }}</div>
                  </div>
                  <div class="author">
                    <img
                      v-if="value && value.avatar"
                      :src="`${urlBackend}/${value.avatar}`"
                      width="150"
                      height="150"
                      class="attachment-thumbnail size-thumbnail wp-post-image"
                      alt=""
                      loading="lazy"
                      sizes="(max-width: 150px) 100vw, 150px"
                    />
                    <img v-else :src="`${no_image}`" />
                    <div>{{ value.name }}</div>
                  </div>
                  <div class="price mb-2">
                    {{
                      value.column &&
                      new Intl.NumberFormat("ja-JP", {
                        style: "currency",
                        currency: "JPY",
                        currencyDisplay: "symbol",
                      }).format(JSON.parse(value.column)[0].product_price)
                    }}
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <button
                    v-if="!check(value.id)"
                    @click="addToCartBtn(value.id)"
                    variant="danger"
                    class="m-auto px-2 btn-success btn-width btn"
                    style="max-width: 75%"
                    :disabled="checkloading(value.id)"
                    :name="`add_content_${value.id}`"
                  >
                    <b-spinner v-if="checkloading(value.id)" small></b-spinner>
                    <span> カートに入れる</span>
                  </button>
                  <b-button
                    v-else
                    class="m-auto px-2"
                    style="cursor: default"
                    :disabled="true"
                  >
                    カートに追加済み
                  </b-button>
                </div>
              </figcaption>
            </figure>
          </a>
          <div
            class="d-flex align-items-center justify-content-center icon-redirect"
            @click="returnDetailContent(value.id)"
          >
            <b-icon icon="chevron-compact-right" font-scale="1.5"></b-icon>
          </div>
        </li>
      </ul>
      <div v-else class="card-content pt-3 row">
        <p class="col-md-12 no-content">著者が作成したコンテンツはありません</p>
      </div>
      <div
        class="d-flex justify-content-center position-relative mx-auto mt-3"
        v-if="listContent.data && listContent.data.length"
      >
        <CPagination
          v-if="listContent.data && listContent.last_page > 1"
          :activePage.sync="page"
          :pages="listContent.last_page"
          align="center"
        />
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import no_image from "@/assets/img/mem.png";
import arrow_image from "@/assets/img/icon_arrow01.png";
import { Constants } from "../../utils/constants";
import moment from "moment";
export default {
  name: "AuthorDetail",
  data() {
    return {
      urlBackend: Constants.URL_BE,
      no_image: no_image,
      arrow_image: arrow_image,
      authors: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 4,
        touchThreshold: 5,
      },
      author: null,
      listContent: [],
      listNews: [],
      page: 1,
      checkData: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      listContentCart: [],
    };
  },
  components: {
    // VueSlickCarousel,
  },
  created() {
    let { id } = this.$route.params;
    const request = {
      shop_id: this.shop_id,
      id: id,
    };
    const data = {
      shop_id: this.shop_id,
      id: id,
      page: this.page,
      limit: Constants.LIMIT_CONTENT_AUTHOR,
    };
    this.getInfoAuthor(request);
    this.getInfoContentAuthor(data);
    const shop_id = this.shop_id;
    this.getListCart({
      shop_id,
    });
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        const shop_id = this.shop_id;
        this.getListCart({
          shop_id,
        });
        const data = {
          shop_id: this.shop_id,
          id: this.$route.params.id,
          page: this.page,
          limit: Constants.LIMIT_CONTENT_AUTHOR,
        };
        this.getInfoContentAuthor(data);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    authorById() {
      this.author = null;
      this.listNews = [];
      this.author = this.authorById;
      // if (this.author && this.author.is_redirect == true) {
      //   if (this.author.url_redirect) {
      //     window.location.href = this.author.url_redirect;
      //     return;
      //   } else {
      //     this.$router.push({
      //       name: this.$route.params.shopId ? "library" : "library domain",
      //     });
      //   }
      // }
    },
    contentAuthor() {
      this.listContent = [];
      let baseDefault = this.$root.$refs.baseDefault;
      this.contentAuthor.data.forEach(function (item) {
        item.title = baseDefault.changeSentence(item.title);
      });
      this.listContent = this.contentAuthor;
    },
    page() {
      let { id } = this.$route.params;
      this.idAuthor = id;
      const data = {
        shop_id: this.shop_id,
        id: id,
        page: this.page,
        limit: Constants.LIMIT_CONTENT_AUTHOR,
      };
      this.getInfoContentAuthor(data);
    },
    listCart() {
      if (this.listCart && this.listCart.length > 0) {
        // this.listCart.forEach((cart) => {
        // if (cart.author_id == this.$route.params.id) {
        this.listContentCart = this.listCart;
        // return;
        // }
        // });
      }
    },
  },
  computed: {
    ...mapGetters([
      "authorById",
      "contentAuthor",
      "listCart",
      "error",
      "success",
      "message",
    ]),
  },
  methods: {
    ...mapActions({
      getInfoAuthor: "getInfoAuthor",
      getListCart: "getListCart",
      getInfoContentAuthor: "getInfoContentAuthor",
      addToCart: "addToCart",
    }),
    moment,
    async addToCartBtn(id) {
      this.checkData = id;
      const shop_id = this.shop_id;
      const formdata = {
        content_id: [id.toString()],
        shop_id: shop_id,
        title: this.$route.meta.title,
        referrer_url: window.location.href,
        url: window.document.activeElement.baseURI,
        id_button: "add_content_" + id,
        add_cart_url:
          window.location.origin +
          this.$router.resolve({
            name: "content detail",
            params: {
              shopId: this.shop_id,
              id: id,
            },
            query: { keyword: "" },
          }).href,
      };
      const dataReturn = await this.$store.dispatch("addToCart", formdata);
      if (dataReturn) {
        if (dataReturn.success) {
          const checkCart = await this.$store.dispatch("getListCart", {
            shop_id,
          });
          if (checkCart) {
            const data = {
              shop_id: this.shop_id,
              id: this.$route.params.id,
              page: this.page,
              limit: Constants.LIMIT_CONTENT_AUTHOR,
            };
            this.getInfoContentAuthor(data);
            this.checkData = null;
          }
        } else {
          this.checkData = null;
        }
      }
    },
    check(id) {
      const listCheck = this.listContentCart;
      var check = false;
      listCheck.filter(function (ele) {
        if (id == ele.content_id) {
          check = true;
          return;
        }
      });
      return check;
    },
    returnDetailContent(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "content detail"
          : "content detail domain",
        params: {
          shopId: this.shop_id,
          id: id,
        },
        query: { keyword: "" },
      });
    },
    checkloading(index) {
      if (this.checkData == index) return true;
      else return false;
    },
  },
};
</script>
<style lang="scss">
.card {
  border-color: #f6f5f5;
}
</style>
